import axios from 'axios'
import * as crypto from 'crypto-js'
import Cookies from 'js-cookie'
import { api } from 'boot/axios'
import { i18n } from 'boot/i18n'
import { AccountType } from 'stores/accounts-store'
import { CancellationPolicy } from 'src/services/AxiosRequestManager'

const { t } = i18n.global
export const COOKIE_EMAIL = 'email'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface Features {
}

export interface MorningBriefing {
  edition: string,
  subscribed_at: number,
  language: string,
  timezone: string
}

export interface UpdatableUserData {
  language: string
  salutation: string
  first_name: string
  last_name: string
  bday: string
  street: string
  number: string
  city: string
  zip: string
  country: string
  phone: string
  newsletter: number,
  invoice_addr_name?: string
  invoice_addr_address_line_1?: string
  invoice_addr_address_line_2?: string
  invoice_addr_zip?: string
  invoice_addr_city?: string
  invoice_addr_country?: string
  vat_id?: string
}

export interface SubscriptionUserData {
  abo_months: unknown,
  subscription_type: string,
  valid_until: number,
  paid_until?: number,
  pendingSubscriptions: AccountType[]
}

export type viewFeatures = 'author' | 'key_event' | 'list' | 'title'
export type filterFeatures = 'source' | 'type' | 'language' | 'key_event' | 'title' | 'combine' | 'sentiment'
export type alertFeatures = 'ai_patterns' | 'buzz_patterns' | 'bsp_patterns' | 'ebs_patterns' | 'key_event_patterns'
  | 'user_messages'
export type aiFeatures = 'summary' | 'topics' | 'translation'
export type toolFeatures = 'download' | 'morning_briefing'
export interface UIFeatures {
  ui_features: {
    views: viewFeatures[],
    filters: filterFeatures[],
    alerts: alertFeatures[],
    ai: aiFeatures[],
    tools: toolFeatures[]
  }
}

export interface LimitUserData {
  max_api_version: number
  max_saved_stocks: number
  max_watchlists: number
  max_stocks_watchlists: number
  max_dashboard_tabs: number
  max_titles_tab: number
  max_subscriptions: number
}

export type UserData = {
  user_id: number
  username: string
  active: number
  validTime: number
  succession: number
  tokenValidUntil: unknown
  registration: number
  nl_json: unknown
  rebate: unknown
  rebate_valid_until: unknown
  filter_id: number
  intraday: number
  delay_minutes: number
  history_months: number
  timespan_days: number | null
  ai_textgen: number
  pulse_picks_regions: string[]
  pulse_picks_count: number
  change_signal_rules: number
  global_signal_rules: number
  active_signal_rules: number
  history_model_type: number
  colorized_messages: number
  filter: unknown[]
  features: Features
  formats: string[]
  nl: {morning_briefings:MorningBriefing[]}
} & UpdatableUserData & SubscriptionUserData & LimitUserData & UIFeatures

export interface LoginResult {
  success: boolean,
  message: string,
  userData: UserData|null
}

export class LoginCredentials {
  username: string
  password: string

  constructor (username: string, password: string) {
    this.username = username
    this.password = password
  }

  toAuthString (): string {
    return btoa(`${this.username}:${crypto.MD5(this.password)}`)
  }
}

export interface RegisterResult {
  success: boolean,
  message: string
}

export interface CancellationRequest {
  firstName: string,
  lastName: string,
  email: string,
  reason: string,
  token: string
}

export class RegisterCredentials {
  username: string
  password: string
  salutation: string|null
  firstName: string
  lastName: string
  token: string

  constructor (
    username: string,
    password: string,
    salutation: string|null,
    firstName: string,
    lastName: string,
    token: string
  ) {
    this.username = username
    this.password = password
    this.salutation = salutation
    this.firstName = firstName
    this.lastName = lastName
    this.token = token
  }
}

export interface ResetResult {
  success: boolean,
  message: string
}

export interface NewPasswordResult {
  success: boolean,
  message: string
}

export interface UpdateUserDataResult {
  success: boolean,
  message: string,
  userData: UserData|null
}

export interface MorningBriefingResult {
  success: boolean,
  message: string,
  userData: UserData|null
}

export interface OrderSubscriptionResult {
  success: boolean,
  message: string,
  userData: UserData|null
}

export interface CancellationResult {
  success: boolean,
  message: string,
  userData: UserData|null
}

interface ApiError {
  en?: string;
  de?: string;
  code?: number;
  internalCode?: string;
}

function isApiError (input: unknown): input is ApiError {
  if (typeof input !== 'object' || input === null) {
    return false
  }

  const obj = input as { [key: string]: unknown }

  if (typeof obj.internalCode !== 'string') {
    return false
  }
  if (obj.en !== undefined && typeof obj.en !== 'string') {
    return false
  }
  if (obj.de !== undefined && typeof obj.de !== 'string') {
    return false
  }
  // noinspection RedundantIfStatementJS
  if (obj.code !== undefined && typeof obj.code !== 'number') {
    return false
  }
  return true
}

class AuthenticationService {
  public async register (credentials: RegisterCredentials, sendEmailConfirmation: boolean): Promise<RegisterResult> {
    if (!credentials.username) {
      return { success: false, message: t('AuthenticationService.message.register.usernameRequired') }
    }
    if (!credentials.password) {
      return { success: false, message: t('AuthenticationService.message.register.passwordRequired') }
    }
    if (!credentials.firstName) {
      return { success: false, message: t('AuthenticationService.message.register.firstNameRequired') }
    }
    if (!credentials.lastName) {
      return { success: false, message: t('AuthenticationService.message.register.lastNameRequired') }
    }
    if (!credentials.token) {
      return { success: false, message: t('AuthenticationService.message.register.tokenRequired') }
    }

    try {
      const body = {
        email: credentials.username,
        password: credentials.password,
        firstName: credentials.firstName,
        lastName: credentials.lastName,
        salutation: credentials.salutation,
        token: credentials.token,
        sendEmailAddressConfirmation: sendEmailConfirmation ? 1 : 0
      }
      await api.post('/v6/accounts/signup', body)

      return { success: true, message: t('AuthenticationService.message.login.success') }
    } catch (error) {
      return {
        success: false,
        message: this.resolveApiErrorCode(error, 'AuthenticationService.message.register.failure')
      }
    }
  }

  public async login (credentials: LoginCredentials, rememberMe: boolean): Promise<LoginResult> {
    if (!credentials.username) {
      return { success: false, message: t('AuthenticationService.message.login.usernameRequired'), userData: null }
    }
    if (!credentials.password) {
      return { success: false, message: t('AuthenticationService.message.login.passwordRequired'), userData: null }
    }

    const authString = credentials.toAuthString()

    try {
      const response = await api.get<UserData>('/v6/userdata', {
        // Add token manually. After success the token will be set globally
        headers: { Authorization: `Basic ${authString}` },
        params: { withPendingSubscriptions: true },
        cancellationPolicy: CancellationPolicy.OnLogout
      })

      if (rememberMe) {
        Cookies.set(COOKIE_EMAIL, credentials.username, { expires: 365, path: '/', sameSite: 'Strict' })
      } else {
        Cookies.remove(COOKIE_EMAIL, { path: '/' })
      }

      return { success: true, message: t('AuthenticationService.message.login.success'), userData: response.data }
    } catch (error) {
      return {
        success: false,
        message: this.resolveApiErrorCode(error, 'AuthenticationService.message.login.failure'),
        userData: null
      }
    }
  }

  public async resetPassword (email: string) : Promise<ResetResult> {
    // from dashboard:
    // eslint-disable-next-line max-len
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    if (!email || email.length < 1 || !emailRegex.test(email)) {
      return { success: false, message: t('AuthenticationService.message.reset.invalidEmail') }
    }

    try {
      const response = await api.get(
        `/userdata/requestpasswordreset/${email}`,
        { cancellationPolicy: CancellationPolicy.OnLogout }
      )
      if (response.status === 200) {
        return { success: true, message: t('AuthenticationService.message.reset.success') }
      }
      return { success: true, message: t('AuthenticationService.message.reset.failure') }
    } catch (error) {
      return {
        success: false,
        message: this.resolveApiErrorCode(error, 'AuthenticationService.message.reset.failure')
      }
    }
  }

  public async setNewPassword (resetToken: string, newPassword: string,
    newPasswordConfirm: string) : Promise<NewPasswordResult> {
    if (!resetToken || resetToken.length < 1) {
      return { success: false, message: t('AuthenticationService.message.setPassword.invalidToken') }
    }
    if (!newPassword || newPassword.length < 1) {
      return { success: false, message: t('AuthenticationService.message.setPassword.invalidPassword') }
    }
    if (!newPasswordConfirm || newPasswordConfirm.length < 1) {
      return { success: false, message: t('AuthenticationService.message.setPassword.invalidPasswordConfirm') }
    }
    if (newPassword.length < 8) {
      return { success: false, message: t('AuthenticationService.message.setPassword.passwordFormat') }
    }
    if (newPassword !== newPasswordConfirm) {
      return { success: false, message: t('AuthenticationService.message.setPassword.passwordMismatch') }
    }

    try {
      const body = {
        reset_token: resetToken,
        newpassword: `${crypto.MD5(newPassword)}`
      }

      const response = await api.post(
        '/userdata/newpassword',
        body,
        { cancellationPolicy: CancellationPolicy.OnLogout }
      )
      if (response.status === 200) {
        return { success: true, message: t('AuthenticationService.message.setPassword.success') }
      }

      return { success: false, message: t('AuthenticationService.message.setPassword.failure') }
    } catch (error) {
      return {
        success: false,
        message: this.resolveApiErrorCode(error, 'AuthenticationService.message.setPassword.failure')
      }
    }
  }

  public async updateUserData (fieldsToUpdate: Partial<UpdatableUserData>): Promise<UpdateUserDataResult> {
    try {
      await api.post('/v6/userdata', fieldsToUpdate, { cancellationPolicy: CancellationPolicy.OnLogout })

      const responseGetData = await api.get<UserData>(
        '/v6/userdata',
        {
          params: { withPendingSubscriptions: true },
          cancellationPolicy: CancellationPolicy.OnLogout
        })
      if (responseGetData.status === 200) {
        return {
          success: true,
          message: t('AuthenticationService.message.updateUserData.success'),
          userData: responseGetData.data
        }
      }
      return { success: false, message: t('AuthenticationService.message.updateUserData.failure'), userData: null }
    } catch (error) {
      return {
        success: false,
        message: this.resolveApiErrorCode(error, 'AuthenticationService.message.updateUserData.failure'),
        userData: null
      }
    }
  }

  public async orderSubscription (subscriptionTypeId: number, paymentOptionId: number): Promise<OrderSubscriptionResult> {
    const body = {
      subscription_type_id: subscriptionTypeId,
      payment_option_id: paymentOptionId
    }
    try {
      const responseData = await api.post('/v6/subscriptions/order', body)
      if (responseData.status === 200) {
        try {
          const responseGetData = await api.get<UserData>(
            '/v6/userdata',
            {
              params: { withPendingSubscriptions: true },
              cancellationPolicy: CancellationPolicy.OnLogout
            }
          )
          if (responseGetData.status === 200) {
            return {
              success: true,
              message: t('AuthenticationService.message.orderSubscription.success'),
              userData: responseGetData.data
            }
          }
          return {
            success: false,
            message: t('AuthenticationService.message.orderSubscription.failure'),
            userData: null
          }
        } catch (error) {
          return {
            success: false,
            message: this.resolveApiErrorCode(error, 'AuthenticationService.message.orderSubscription.failure'),
            userData: null
          }
        }
      }
      return { success: false, message: t('AuthenticationService.message.orderSubscription.failure'), userData: null }
    } catch (error) {
      return {
        success: false,
        message: this.resolveApiErrorCode(error, 'AuthenticationService.message.orderSubscription.failure'),
        userData: null
      }
    }
  }

  public async subscribeToMorningBriefing (edition: string, language: string): Promise<MorningBriefingResult> {
    const body = {
      edition,
      language,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    }
    try {
      await api.post('/v6/morning-briefings/subscriptions', body)

      const responseGetData = await api.get<UserData>(
        '/v6/userdata',
        {
          params: { withPendingSubscriptions: true },
          cancellationPolicy: CancellationPolicy.OnLogout
        })
      if (responseGetData.status === 200) {
        return {
          success: true,
          message: t('AuthenticationService.message.updateUserData.success'),
          userData: responseGetData.data
        }
      }
      return { success: false, message: t('AuthenticationService.message.updateUserData.failure'), userData: null }
    } catch (error) {
      return {
        success: false,
        message: this.resolveApiErrorCode(error, 'AuthenticationService.message.updateUserData.failure'),
        userData: null
      }
    }
  }

  public async unsubscribeMorningBriefing (edition: string): Promise<MorningBriefingResult> {
    try {
      await api.delete(`/v6/morning-briefings/subscriptions/${edition}`)

      const responseGetData = await api.get<UserData>(
        '/v6/userdata',
        {
          params: { withPendingSubscriptions: true },
          cancellationPolicy: CancellationPolicy.OnLogout
        })
      if (responseGetData.status === 200) {
        return {
          success: true,
          message: t('AuthenticationService.message.updateUserData.success'),
          userData: responseGetData.data
        }
      }
      return { success: false, message: t('AuthenticationService.message.updateUserData.failure'), userData: null }
    } catch (error) {
      return {
        success: false,
        message: this.resolveApiErrorCode(error, 'AuthenticationService.message.updateUserData.failure'),
        userData: null
      }
    }
  }

  public async cancelSubscription (cancellationRequest: CancellationRequest): Promise<CancellationResult> {
    try {
      const responseGetData = await api.post('/v6/subscriptions/cancel', cancellationRequest)
      if (responseGetData.status === 200) {
        return {
          success: true,
          message: t('AuthenticationService.message.cancellation.success'),
          userData: responseGetData.data
        }
      }
      return { success: false, message: t('AuthenticationService.message.cancellation.failure'), userData: null }
    } catch (error) {
      return {
        success: false,
        message: this.resolveApiErrorCode(error, 'AuthenticationService.message.cancellation.failure'),
        userData: null
      }
    }
  }

  private resolveApiErrorCode (error: unknown, fallbackErrorCode: string): string {
    if (axios.isAxiosError(error)) {
      const apiError = error.response?.data
      if (isApiError(apiError)) {
        if (apiError.internalCode) {
          const apiTranslationKey = 'AuthenticationService.api.' + apiError.internalCode
          const apiTranslation = t(apiTranslationKey)
          if (apiTranslation !== apiTranslationKey) {
            return apiTranslation
          }
          if (apiError.en) {
            return apiError.en
          }
        }
      }
    }

    return t(fallbackErrorCode)
  }
}

export default AuthenticationService
